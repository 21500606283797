import React, { useContext, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import lang, { ILang, Language } from '../languages';
import { IStoreContext, StoreContext } from '../providers/StoreProvider';
import { ITheme } from '../theme';
import IconButton from './IconButton';

const LanguageSwitch = () => {
    const classes = useStyle();
    const { t } = useTranslation();

    const { changeLanguage, language }: IStoreContext = useContext(StoreContext);

    const [selectLanguage, setSelectLanguage] = useState<string>(
        language ? language.toString() : lang[0].key.toString()
    );

    useEffect(() => {
        if (language === null) {
            return;
        }

        setSelectLanguage(language.toString());
    }, [language]);

    const onSubmitLanguage = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        changeLanguage(selectLanguage as Language);
    };

    const onChangeSelection = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectLanguage(event.target.value);
    };

    return (
        <form onSubmit={onSubmitLanguage} className={classes.form}>
            <label htmlFor="lang">{t('settings.languageLabel')}</label>
            <select
                name="lang"
                id="lang"
                className={classes.languageSelector}
                onChange={onChangeSelection}
                value={selectLanguage}
            >
                {lang.map((l: ILang) => (
                    <option key={l.key} value={l.key}>
                        {l.name}
                    </option>
                ))}
            </select>
            <IconButton
                type="submit"
                label={t('start.buttonSubmitPassword')}
                icon="confirm"
            />
        </form>
    );
};

export default LanguageSwitch;

const useStyle = createUseStyles((theme: ITheme) => ({
    form: {
        display: 'grid',
        gridTemplateColumns: 'auto auto auto',
        alignItems: 'center',
        justifyContent: 'center',
        gridGap: `${theme.spacing.outer}px`,

        [theme.mediaQueries.mobile.max]: {
            gridTemplateColumns: 'auto',
            justifyItems: 'center',
            gridGap: `${theme.spacing.inner}px`,
        },
    },
    languageSelector: {
        fontSize: theme.typography.normal.size,
        background: 'transparent',
        color: theme.palette.main.text,
        borderColor: theme.palette.main.sub,
        minWidth: 150,
        borderRadius: theme.border.radius,

        '& *': {
            color: '#000',
        },
    },
}));
