import React, { createContext, Dispatch, useCallback, useEffect, useState } from 'react';
import { ThemeProvider } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import saddleFormConfig from '../assets/saddleFormConfig';
import soleFormConfig from '../assets/soleFormConfig';
import { Config, IResult } from '../gebiomized';
import { Language } from '../languages';
import theme, { saddlePalette, solePalette } from '../theme';

interface IProps {
    children: any;
}

export enum Selector {
    Saddle = 'saddle',
    Sole = 'sole',
}

export interface IStoreContext {
    isLoggedInSaddle: boolean;
    loginSaddle: { (password: string): boolean };
    isLoggedInSole: boolean;
    loginSole: { (password: string): boolean };
    results: IResult;
    setOption: { (key: string, value: string | number): void };
    reset: { (): void };
    language: Language | null;
    changeLanguage: { (language: Language): void };
    config: Config;
    selector: Selector;
    enterSole: () => void;
    enterSaddle: () => void;
}

export const StoreContext = createContext({} as IStoreContext);

const localStorageLoginKeySaddle = 'ssc.isLoggedIn';
const localStorageLoginKeySole = 'ssc.isLoggedInSole';
const localStorageLanguageKey = 'ssc.language';
const isDebugMode = process.env.REACT_APP_DEBUG === 'true';

const setLocalStorageItem = (key: string, value: string) => {
    if (window.localStorage) {
        window.localStorage.setItem(key, value);
    }
};

const StoreProvider = ({ children }: IProps) => {
    const [selector, setSelector] = useState<Selector>(Selector.Saddle);
    const [results, setResults] = useState(new Map());
    const [isLoggedInSaddle, setIsLoggedInSaddle] = useState(isDebugMode);
    const [isLoggedInSole, setIsLoggedInSole] = useState(isDebugMode);
    const [language, setLanguage] = useState<Language>(Language.En);
    const navigate = useNavigate();
    const { i18n } = useTranslation();

    const loginFromLocalStorageWithKey = (key: string, setState: Dispatch<any>) => {
        const localStorageSaddleLoginValue: string | null = window.localStorage.getItem(key);
        setState(localStorageSaddleLoginValue === 'true');
    };

    useEffect(() => {
        if (!(window.localStorage && !isDebugMode)) {
            return;
        }

        loginFromLocalStorageWithKey(localStorageLoginKeySaddle, setIsLoggedInSaddle);
        loginFromLocalStorageWithKey(localStorageLoginKeySole, setIsLoggedInSole);
        const localStorageLanguageValue: string | null = window.localStorage.getItem(
            localStorageLanguageKey
        );
        const lang = localStorageLanguageValue
            ? (localStorageLanguageValue as Language)
            : Language.En;
        setLanguage(lang);
        i18n.changeLanguage(lang);
    }, [i18n]);

    const setOption = useCallback((key: string, value: string | number) => {
        setResults((prevResult: IResult) => {
            const newMap = new Map<string, string | number>(prevResult);
            newMap.set(key, value);
            return newMap;
        });
    }, []);

    const reset = () => {
        setResults(new Map());
    };

    const loginSaddle = (password: string): boolean => {
        if (password !== '20SSC20') {
            return false;
        }

        setIsLoggedInSaddle(true);
        setLocalStorageItem(localStorageLoginKeySaddle, 'true');

        return true;
    };

    const loginSole = (password: string): boolean => {
        if (password !== '21SOLE21') {
            return false;
        }

        setIsLoggedInSole(true);
        setLocalStorageItem(localStorageLoginKeySole, 'true');
        return true;
    };

    const enterSole = (): void => {
        setSelector(Selector.Sole);
        navigate('/form/0');
    };

    const enterSaddle = (): void => {
        setSelector(Selector.Saddle);
        navigate('/form/0');
    };

    const changeLanguage = async (language: Language) => {
        setLanguage(language);
        setLocalStorageItem(localStorageLanguageKey, language);
        await i18n.changeLanguage(language);
    };

    return (
        <StoreContext.Provider
            value={{
                loginSaddle,
                isLoggedInSaddle,
                loginSole,
                isLoggedInSole,
                results,
                setOption,
                reset,
                language,
                changeLanguage,
                config: selector === Selector.Sole ? soleFormConfig : saddleFormConfig,
                selector,
                enterSaddle,
                enterSole,
            }}
        >
            <ThemeProvider
                theme={{
                    ...theme,
                    palette: selector === Selector.Sole ? solePalette : saddlePalette,
                }}
            >
                {children}
            </ThemeProvider>
        </StoreContext.Provider>
    );
};

export default StoreProvider;
