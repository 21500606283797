import React from 'react';
import Grid from '../components/Grid';
import IconButton from '../components/IconButton';

const ErrorPage = () => (
    <Grid>
        <div>
            <h1>Ein Fehler ist aufgetreten</h1>
        </div>
        <div>
            <IconButton label="Back to home screen" to="/" icon="home" />
        </div>
    </Grid>
);

export default ErrorPage;
