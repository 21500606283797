import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { ITheme } from '../theme';
import IconButton from './IconButton';
import Modal, { IAction } from './Modal';

const useStyle = createUseStyles((theme: ITheme) => ({
    icon: {
        width: 40,
        verticalAlign: 'baseline',
        '& .fa-icon': {
            fontSize: theme.typography.normal.size,
            color: theme.palette.ci.primary,
        },
    },
}));

interface IProps {
    title: string;
    children: any;
}

const ToolTip = ({ title, children }: IProps) => {
    const classes = useStyle();
    const [open, setOpen] = useState(false);

    const toggleOpen = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const action: IAction = {
        icon: 'close',
        label: 'Close',
        onClick: toggleOpen,
    };

    return (
        <>
            <IconButton
                className={classes.icon}
                onClick={toggleOpen}
                icon="tooltip"
                label="Open Help"
            />
            {
                open && (
                    <Modal  actions={[action]} title={title}>
                        {children}
                    </Modal>
                )
            }
        </>
    );
};

export default ToolTip;
