import aera145Channel from '../assets/saddles/area-145-channel.png';
import aera145CutOut from '../assets/saddles/area-145-cutout.png';
import aera155Channel from '../assets/saddles/area-155-channel.png';
import aera155CutOut from '../assets/saddles/area-155-cutout.png';
import sleak135Channel from '../assets/saddles/sleak-135-channel.png';
import sleak135CutOut from '../assets/saddles/sleak-135-cutout.png';
import sleak145Channel from '../assets/saddles/sleak-145-channel.png';
import sleak145CutOut from '../assets/saddles/sleak-145-cutout.png';

const saddles: ISaddle[] = [
    {
        name: 'SLEAK 135',
        image: {
            channel: sleak135Channel,
            cutout: sleak135CutOut,
        },
        criteria: {
            male: {
                points: 1,
                friction: {
                    yes: 2,
                    no: 0,
                },
                contactArea: {
                    front: 0,
                    middle: 0,
                    back: 1,
                },
                movement: {
                    1: 0,
                    2: 0,
                    3: 1,
                    4: 2,
                },
            },
            female: {
                points: 0,
                friction: {
                    yes: 2,
                    no: 0,
                },
                contactArea: {
                    front: 0,
                    middle: 0,
                    back: 2,
                },
                movement: {
                    1: 0,
                    2: 0,
                    3: 1,
                    4: 2,
                },
            },
        },
    } as ISaddle,
    {
        name: 'SLEAK 145',
        image: {
            channel: sleak145Channel,
            cutout: sleak145CutOut,
        },
        criteria: {
            male: {
                points: 1,
                friction: {
                    yes: 0,
                    no: 1,
                },
                contactArea: {
                    front: 1,
                    middle: 1,
                    back: 0,
                },
                movement: {
                    1: 1,
                    2: 0,
                    3: 0,
                    4: 0,
                },
            },
            female: {
                points: 0,
                friction: {
                    yes: 0,
                    no: 1,
                },
                contactArea: {
                    front: 2,
                    middle: 2,
                    back: 0,
                },
                movement: {
                    1: 1,
                    2: 0,
                    3: 0,
                    4: 0,
                },
            },
        },
    } as ISaddle,
    {
        name: 'AREA 145',
        image: {
            channel: aera145Channel,
            cutout: aera145CutOut,
        },
        criteria: {
            male: {
                points: 0,
                friction: {
                    yes: 0,
                    no: 1,
                },
                contactArea: {
                    front: 1,
                    middle: 1,
                    back: 0,
                },
                movement: {
                    1: 0,
                    2: 1,
                    3: 0,
                    4: 0,
                },
            },
            female: {
                points: 1,
                friction: {
                    yes: 0,
                    no: 1,
                },
                contactArea: {
                    front: 2,
                    middle: 2,
                    back: 0,
                },
                movement: {
                    1: 0,
                    2: 1,
                    3: 0,
                    4: 0,
                },
            },
        },
    } as ISaddle,
    {
        name: 'AREA 155',
        image: {
            channel: aera155Channel,
            cutout: aera155CutOut,
        },
        criteria: {
            male: {
                points: 0,
                friction: {
                    yes: 2,
                    no: 0,
                },
                contactArea: {
                    front: -1,
                    middle: -1,
                    back: 1,
                },
                movement: {
                    1: 1,
                    2: 0,
                    3: 0,
                    4: 0,
                },
            },
            female: {
                points: 1,
                friction: {
                    yes: 2,
                    no: 0,
                },
                contactArea: {
                    front: -2,
                    middle: -2,
                    back: 1,
                },
                movement: {
                    1: 1,
                    2: 0,
                    3: 0,
                    4: 0,
                },
            },
        },
    } as ISaddle,
];

interface ICriteriaConfig {
    points: number;
    friction: {
        yes: number;
        no: number;
    };
    contactArea: {
        front: number;
        middle: number;
        back: number;
    };
    movement: {
        1: number;
        2: number;
        3: number;
        4: number;
    };
}

export interface ISaddle {
    name: string;
    image: {
        channel: string;
        cutout: string;
    };
    criteria: {
        male: ICriteriaConfig;
        female: ICriteriaConfig;
    };
}

export default saddles;
