import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '../components/Grid';
import IconButton from '../components/IconButton';
import LanguageSwitch from '../components/LanguageSwitch';

const SettingsPage = () => {
    const { t } = useTranslation();

    return (
        <Grid>
            <div>
                <h1>{t('appName')}</h1>
            </div>
            <div>
                <LanguageSwitch />
            </div>
            <div>
                <IconButton label={t('info.buttonBackToHome')} to='/' icon='home' />
            </div>
        </Grid>
    );
};

export default SettingsPage;
