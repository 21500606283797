import backgroundPatternPrism from './assets/images/prism.svg';
import backgroundPattern from './assets/images/wiggle.svg';

const vars = {
    typography: {
        normal: {
            size: '--typography-normal-size',
        },
        headline: {
            size: '--typography-headline-size',
        },
    },
};

export const saddlePalette = {
    main: {
        background: '#1f0b02',
        text: '#e8e8e8',
        headline: '#989898',
        sub: '#989898',
        transparent: 'rgba(0, 0, 0, 0.8)',
        border: '#ffffff2b',
    },
    second: {
        background: '#fff',
        text: '#000',
    },
    third: {
        background: '#1f1f1f',
        color: '#e0e0e0',
        sub: '#989898',
    },
    ci: {
        primary: '#cf4709',
    },
    action: {
        success: 'green',
    },
    wallpaper: backgroundPattern,
};

export const solePalette = {
    main: {
        background: '#ffffff',
        text: '#101010',
        headline: '#989898',
        sub: '#3d3d3d',
        transparent: 'rgba(0, 0, 0, 0.8)',
        border: '#3d3d3d',
    },
    second: {
        background: '#2c2c2c',
        text: '#e3e3e3',
    },
    third: {
        background: '#1f1f1f',
        color: '#e0e0e0',
        sub: '#989898',
    },
    ci: {
        primary: '#2661b9',
    },
    action: {
        success: 'green',
    },
    wallpaper: backgroundPatternPrism,
};

const breakPoints = {
    mobile: 576,
    tablet: 768,
    desktop: 1024,
};

const theme = {
    typography: {
        normal: {
            size: `var(${vars.typography.normal.size})`,
            weight: 300,
            family: '"lato", sans-serif',
        },
        headline: {
            family: '"oswald", sans-serif',
            size: `var(${vars.typography.headline.size})`,
            weight: 400,
        },
    },
    spacing: {
        inner: 15,
        outer: 60,
    },
    palette: saddlePalette,
    border: {
        iconButton: `3px solid ${saddlePalette.main.text}`,
        small: `1px solid ${saddlePalette.main.border}`,
        radius: 10,
    },
    transition: (target: string) => `${target} 0.3s ease-in-out`,
    shadows: {
        first: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    },
    modules: {},
    breakPoints,
    mediaQueries: {
        mobile: {
            max: `@media screen and (max-width: ${breakPoints.mobile}px)`,
            min: `@media screen and (min-width: ${breakPoints.mobile + 1}px)`,
        },
        tablet: {
            max: `@media screen and (max-width: ${breakPoints.tablet}px)`,
            min: `@media screen and (min-width: ${breakPoints.tablet + 1}px)`,
        },
        desktop: {
            max: `@media screen and (max-width: ${breakPoints.desktop}px)`,
            min: `@media screen and (min-width: ${breakPoints.desktop + 1}px)`,
        },
    },
};

export type ITheme = typeof theme;

export default theme;

export { vars };
