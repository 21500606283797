import React, { useContext } from 'react';
import { createUseStyles } from 'react-jss';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ProgressBar from '../components/form/ProgressBar';
import Section from '../components/form/Section';
import Grid from '../components/Grid';
import IconButton from '../components/IconButton';
import ResetButton from '../components/ResetButton';
import SoleSection, { soleSides } from '../components/sole/SoleSection';
import { IStoreContext, Selector, StoreContext } from '../providers/StoreProvider';
import { ITheme } from '../theme';

const useStyle = createUseStyles((theme: ITheme) => ({
    grid: {
        gridGap: `${theme.spacing.outer}px`,
        [theme.mediaQueries.tablet.max]: {
            marginBottom: 100,
        },
    },
    section: {
        display: 'grid',
        width: '100%',
        justifyContent: 'space-around',
        '& > *': {
            gridRow: 1,
        },
    },
    actionsWrapper: {
        background: theme.palette.third.background,
        borderRadius: theme.border.radius,
        [theme.mediaQueries.tablet.max]: {
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            borderRadius: 0,
        },
    },
    actions: {
        padding: theme.spacing.inner,
        display: 'grid',
        justifyContent: 'space-between',

        '& > *': {
            gridRow: 1,
        },
        '& .fa-icon': {
            color: theme.palette.third.color,
            fontSize: theme.typography.normal.size,
        },
    },
}));

// @ts-ignore
const FormPage = () => {
    const classes = useStyle();
    const {
        results,
        isLoggedInSaddle,
        config,
        selector,
        isLoggedInSole,
    }: IStoreContext = useContext(StoreContext);
    const { page } = useParams<{ page: string }>();

    const { t } = useTranslation();
    const navigate = useNavigate();

    if (!isLoggedInSaddle && !isLoggedInSole) {
        return <ResetButton />;
    }

    if (!page) {
        navigate('/404');
    }

    const currentPageNumber: number = parseInt(page!!, 10);
    const currentPageBlock = config.pages[currentPageNumber];

    if (!currentPageBlock) {
        navigate('/404');
    }

    const isFinished = currentPageBlock.fields
        .map(field => field.key)
        .every((fieldKey: string) => {
            if (selector === Selector.Sole) {
                const sides = Object.keys(soleSides);
                return sides.every(side => {
                    const keyWithPrefix = `${side}_${fieldKey}`;
                    return results.has(keyWithPrefix) && results.get(keyWithPrefix);
                });
            }
            return results.has(fieldKey) && results.get(fieldKey);
        });
    const nextButtonIcon = currentPageNumber + 1 < config.pages.length ? 'right' : 'confirm';

    const onClickNextButton = () => {
        if (!isFinished) {
            return null;
        }

        const nextTo =
            currentPageNumber + 1 < config.pages.length
                ? `/form/${currentPageNumber + 1}`
                : '/result';

        navigate(nextTo);
    };

    const onClickPrevButton = () => {
        if (currentPageNumber === 0) {
            return null;
        }

        navigate(`/form/${currentPageNumber - 1}`);
    };

    return (
        <Grid className={classes.grid}>
            {currentPageBlock.title && <div>{t(currentPageBlock.title)}</div>}
            {currentPageBlock.fields.map(field =>
                selector === Selector.Sole ? (
                    <SoleSection key={field.title} field={field} />
                ) : (
                    <Section key={field.title} field={field} />
                )
            )}
            <div className={classes.actionsWrapper}>
                <ProgressBar
                    currentPageNumber={currentPageNumber}
                    totalPagesCount={config.pages.length}
                />
                <div className={classes.actions}>
                    <IconButton
                        onClick={onClickPrevButton}
                        label={t('form.previous')}
                        icon="left"
                        disabled={currentPageNumber - 1 < 0}
                    />
                    <ResetButton />
                    <IconButton
                        onClick={onClickNextButton}
                        disabled={!isFinished}
                        label={t('form.next')}
                        icon={nextButtonIcon}
                    />
                </div>
            </div>
        </Grid>
    );
};

export default FormPage;
