import 'rc-slider/assets/index.css';
import React, { useContext } from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { IConfigField, IConfigFieldOption } from '../../gebiomized';
import { IStoreContext, StoreContext } from '../../providers/StoreProvider';
import { ITheme } from '../../theme';
import Text from '../Text';
import ToolTip from '../ToolTip';
import Range from './Range';
import SectionOption from './SectionOption';

const useStyle = createUseStyles((theme: ITheme) => ({
    section: {
        transition: theme.transition('all'),
        display: 'grid',
        gridGap: `${theme.spacing.inner}px`,
        height: '100%',
    },
    title: {
        textAlign: 'center',
        lineHeight: 1,
    },
    options: {
        display: 'grid',
        width: '100%',
        justifyContent: 'space-around',
        transition: theme.transition('all'),
        '& > *': {
            gridRow: 1,
        },
    },

    meta: {
        display: 'grid',
        gridGap: `${theme.spacing.outer}px`,
        gridTemplateColumns: '1fr 1fr',
        height: '100%',
        overflowX: 'scroll',

        [theme.mediaQueries.mobile.max]: {
            gridTemplateColumns: '1fr',
            gridGap: `${theme.spacing.inner}px`,
        },

        '& p': {
            margin: 0,
            textAlign: 'left',
        },

        '& img': {
            width: '100%',
            maxWidth: '100%',
            height: 'auto',
            borderRadius: theme.border.radius,
            display: 'inline-block',
            margin: '0 auto',

            [theme.mediaQueries.mobile.max]: {
                maxWidth: 200,
            },
        },
    },
}));

interface IProps {
    field: IConfigField;
}

const Section = ({ field }: IProps) => {
    const { results, setOption } = useContext<IStoreContext>(StoreContext);
    const classes = useStyle();
    const { t } = useTranslation();

    const onClickOption = (v: string) => {
        setOption(field.key, v);
    };

    const value = results.get(field.key);
    const rangeValue: string | number = results.has(field.key) && value ? value : '';

    return (
        <div
            className={classes.section}
            style={{ gridTemplateRows: field.meta ? 'auto 1fr auto' : '1fr auto' }}
        >
            <div>
                <h2 className={classes.title}>{t(field.title)}</h2>
                {field.tip && <ToolTip title={field.title}>{field.tip}</ToolTip>}
            </div>
            {field.meta && (
                <div className={classes.meta}>
                    <img src={field.meta.image} alt={field.title} />
                    <Text text={t(field.meta.description)} />
                </div>
            )}

            {field.options && (
                <div className={classes.options}>
                    {field.options.map((option: IConfigFieldOption) => (
                        <SectionOption
                            value={rangeValue.toString()}
                            key={option.value}
                            option={option}
                            onClickOption={onClickOption}
                        />
                    ))}
                </div>
            )}
            {field.range && <Range field={field} />}
        </div>
    );
};

export default Section;
