import i18next from '../i18n';
import saddles, { ISaddle } from '../assets/saddlesConfig';
import { Config, IConfigField, IResult, IResultSaddle } from '../gebiomized';

export const createTestResult = (config: Config): IResult => {
    const t: IResult = new Map();
    config.pages.forEach(page => {
        page.fields.forEach((f: IConfigField) => {
            let v = '';
            if (f.range) {
                v = (Math.floor(Math.random() * f.range.max) + f.range.min).toString();
            }
            if (f.options) {
                v = f.options[Math.floor(Math.random() * f.options.length)].value;
            }

            t.set(f.key, v);
        });
    });

    return t;
};

export const calculateSaddle = (
    results: IResult,
    config: Config,
    isInDebugMode: boolean = false
): IResultSaddle[] => {
    const calculatedSaddles = saddles.map((s: ISaddle) => {
        const debug: string[] = [];
        const criteria = s.criteria[results.get('gender') as 'male' | 'female'];

        const pointsGender = criteria.points;
        debug.push(`${results.get('gender')}: +${pointsGender}`);

        const points: number =
            pointsGender +
            Object.keys(criteria)
                .filter((key: string) => key !== 'points')
                .reduce((o: number, key: string) => {
                    // @ts-ignore
                    const value = criteria[key][results.get(key)];

                    // Debug
                    const label = config.pages
                        .flatMap(p => p.fields)
                        .filter((q: any) => q.key === key)[0].title;

                    debug.push(`${label}: ${results.get(key)} = ${value >= 0 ? '+' : ''}${value}`);

                    return o + value;
                }, 0);

        const sensitivity = results.has('sensitivity') ? results.get('sensitivity') : '0';
        // @ts-ignore
        const isChannel = parseInt(sensitivity, 10) < 5;
        const variant = isChannel ? 'Channel' : 'Cut-Out';

        debug.push(`Empfindlichkeit am Sattel: ${sensitivity} = ${variant}`);

        const saddleKey = s.name.replace(' ', '').toLowerCase();

        return {
            points,
            name: `${s.name} - ${variant}`,
            features: isInDebugMode
                ? debug
                : (i18next.t(`saddles.${saddleKey}.features`, { returnObjects: true }) as string[]),
            image: isChannel ? s.image.channel : s.image.cutout,
        };
    });

    return calculatedSaddles.sort((a, b) => b.points - a.points);
};
