import React from 'react';
import ReactDOM from 'react-dom';
import { createUseStyles } from 'react-jss';
import { AnimatePresence } from 'framer-motion';
import { ITheme } from '../theme';
import { IIcon } from './Icon';
import IconButton from './IconButton';

const useStyle = createUseStyles((theme: ITheme) => ({
    wrapper: {
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 10,
    },
    background: {
        background: theme.palette.main.transparent,
        padding: 10,

        '&, & > div': {
            height: '100%',
        },
    },
    modal: {
        background: theme.palette.second.background,
        color: theme.palette.second.text,
        position: 'absolute',
        top: '50vh',
        left: '50vw',
        transform: 'translate(-50%, -50%)',
        display: 'grid',
        gridGap: `${theme.spacing.inner}px`,
        padding: theme.spacing.inner,
        maxWidth: 400,
        boxShadow: theme.shadows.first,
        borderRadius: theme.border.radius,
        width: '90%',
    },
    title: {
        fontFamily: theme.typography.headline.family,
        fontWeight: theme.typography.headline.weight,
        fontSize: theme.typography.headline.size,
    },
    body: {
        lineHeight: 2,
        fontSize: theme.typography.normal.size,
    },
    actions: {
        display: 'grid',
        justifyContent: 'space-between',
        '& > *': {
            gridRow: 1,
            width: '50px',
            '& .fa-icon': {
                fontSize: theme.typography.normal.size,
                color: theme.palette.second.text + ' !important',
            },
        },
    },
}));

export interface IAction {
    icon: IIcon;
    label: string;
    onClick: { (): void };
}

interface IProps {
    title: string;
    children: any;
    actions: IAction[];
}

const Modal = ({ children, title, actions }: IProps) => {
    const classes = useStyle();

    const targetElement = document.getElementById('root');

    if (!targetElement) {
        return null;
    }

    return ReactDOM.createPortal(
        <AnimatePresence exitBeforeEnter>
            <div className={classes.wrapper} key={title}>
                <div className={classes.background}>
                    <div>
                        <div className={classes.modal}>
                            <div className={classes.title}>{title}</div>
                            <div className={classes.body}>{children}</div>
                            <div className={classes.actions}>
                                {actions.map((action: IAction) => (
                                    <IconButton key={action.label} {...action} />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AnimatePresence>,
        targetElement
    );
};

export default Modal;
