import React from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ITheme } from '../theme';

const useStyle = createUseStyles((theme: ITheme) => ({
    title: {
        color: theme.palette.third.color,
        textAlign: 'center',
        display: 'block',
        padding: theme.spacing.outer,

        [theme.mediaQueries.tablet.max]: {
            display: 'none',
        },
    },
    main: {
        textAlign: 'center',
        padding: theme.spacing.outer,
        maxWidth: theme.breakPoints.desktop,
        marginLeft: 'auto',
        marginRight: 'auto',

        [theme.mediaQueries.desktop.min]: {
            backgroundImage: `url(${theme.palette.wallpaper})`,
            background: theme.palette.main.background,
            borderRadius: theme.border.radius,
            boxShadow: theme.shadows.first,
        },

        [theme.mediaQueries.mobile.max]: {
            padding: theme.spacing.outer / 2,
        },
    },
    footer: {
        padding: theme.spacing.outer,
        textAlign: 'center',
        color: theme.palette.third.sub,
        letterSpacing: 1,

        '& a': {
            color: theme.palette.third.color,
            textDecoration: 'none',
            transition: theme.transition('color'),

            '&:hover': {
                color: theme.palette.ci.primary,
            },
        },

        [theme.mediaQueries.tablet.max]: {
            display: 'none',
        },
    },
}));

interface IProps {
    children: any;
}

const Layout = ({ children }: IProps) => {
    const classes = useStyle();
    const { t } = useTranslation();

    return (
        <>
            <h1 className={classes.title}>{t('appName')}</h1>
            <main className={classes.main}>{children}</main>
            <footer className={classes.footer}>
                &copy; {new Date().getFullYear()} by{' '}
                <a href={t('companyUrl')}>{t('companyName')}</a> -{' '}
                <Link to="/info">Impressum</Link>
            </footer>
        </>
    );
};

export default Layout;
