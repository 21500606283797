import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import de from './translations/de.json';
import en from './translations/en.json';
import nl from './translations/nl.json';
import pl from './translations/pl.json';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    de: {
        translation: de,
    },
    en: {
        translation: en,
    },
    nl: {
        translation: nl,
    },
    pl: {
        translation: pl,
    },
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: 'de',

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
