import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
    faBicycle,
    faCar,
    faCheck,
    faChevronLeft,
    faChevronRight,
    faHome,
    faInfo,
    faLanguage,
    faPlay,
    faQuestion,
    faSpaceShuttle,
    faSpinner,
    faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { createUseStyles } from 'react-jss';
import { ReactSVG } from 'react-svg';
import genderFemale from '../assets/icons/frau.svg';
import genderMale from '../assets/icons/mann.svg';
import PositionAero from '../assets/icons/position-aero.svg';
import PositionAufrecht from '../assets/icons/position-aufrecht.svg';
import PositionModerat from '../assets/icons/position-moderat.svg';
import saddleBack from '../assets/icons/sattel-hinten.svg';
import saddleMiddle from '../assets/icons/sattel-mittig.svg';
import saddleFront from '../assets/icons/sattel-vorne.svg';
import { ITheme } from '../theme';

const useStyle = createUseStyles((theme: ITheme) => ({
    svg: {
        fill: theme.palette.main.text,
    },
}));

export type IIcon =
    | 'home'
    | 'left'
    | 'right'
    | 'tooltip'
    | 'close'
    | 'confirm'
    | 'info'
    | 'loading'
    | 'start'
    | 'settings'
    | 'form_gender_male'
    | 'form_gender_female'
    | 'form_discipline_rr'
    | 'form_discipline_mbt'
    | 'form_discipline_gravel'
    | 'form_position_aufrecht'
    | 'form_position_moderat'
    | 'form_position_aerodynamisch'
    | 'form_contactarea_front'
    | 'form_contactarea_middle'
    | 'form_contactarea_back';

interface IProps {
    icon: IIcon;
    spin?: boolean;
    className?: string;
}

const getFaIconFromString = (icon: any): IconProp => {
    const i: any = {
        home: faHome,
        left: faChevronLeft,
        right: faChevronRight,
        tooltip: faQuestion,
        close: faTimes,
        confirm: faCheck,
        loading: faSpinner,
        start: faPlay,
        settings: faLanguage,
        info: faInfo,

        form_discipline_rr: faCar,
        form_discipline_mbt: faBicycle,
        form_discipline_gravel: faSpaceShuttle,
    };
    return i[icon];
};

const getSVGFromString = (icon: string) => {
    const i: any = {
        form_gender_male: genderMale,
        form_gender_female: genderFemale,

        form_position_aufrecht: PositionAufrecht,
        form_position_moderat: PositionModerat,
        form_position_aerodynamisch: PositionAero,

        form_contactarea_front: saddleFront,
        form_contactarea_middle: saddleMiddle,
        form_contactarea_back: saddleBack,
    };
    return i[icon];
};

const Icon = ({ className, icon, spin }: IProps) => {
    const classes = useStyle();
    const svgPath = getSVGFromString(icon);
    return svgPath ? (
        <ReactSVG src={svgPath} className={[classes.svg, className, 'svg'].join(' ')} />
    ) : (
        <FontAwesomeIcon icon={getFaIconFromString(icon)} className={className} spin={spin} />
    );
};

export default Icon;
