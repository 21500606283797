import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import IconButton from './IconButton';
import Modal, { IAction } from './Modal';

const ResetButton = () => {
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const toggle = () => setShowModal(prevState => !prevState);
    const resetAndGoBack = () => {
        navigate('/');
    };

    const closeAction: IAction = {
        label: t('reset.modal.cancel'),
        icon: 'close',
        onClick: toggle,
    };
    const resetAction: IAction = {
        label: t('reset.modal.confirm'),
        icon: 'home',
        onClick: resetAndGoBack,
    };

    return (
        <>
            <IconButton onClick={toggle} label={t('reset.button')} icon="home" />
            {
                showModal && (
                    <Modal
                        title={t('reset.modal.title')}
                        actions={[closeAction, resetAction]}
                    >
                        {t('reset.modal.text')}
                    </Modal>
                )
            }
        </>
    );
};

export default ResetButton;
