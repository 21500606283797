import React from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import geenenLogo from '../assets/images/geenen-it-systeme-logo-compressed.svg';
import Grid from '../components/Grid';
import IconButton from '../components/IconButton';
import Text from '../components/Text';
import { ITheme } from '../theme';

const useStyle = createUseStyles((theme: ITheme) => ({
    impressum: {
        display: 'inline-block',
        width: 'auto',

        '& p': {
            fontSize: theme.typography.normal.size,
            lineHeight: 1.5,
        },
    },
    technical: {
        '& img': {
            display: 'inline-block',
            width: 250,

            [theme.mediaQueries.mobile.max]: {
                width: 200,
            },
        },
        '& a': {
            color: theme.palette.ci.primary,
            textDecoration: 'none',
        },
    },
}));

const InfoPage = () => {
    const classes = useStyle();
    const { t } = useTranslation();
    
    return (
        <Grid>
            <div>
                <h1>{t('appName')}</h1>
            </div>
            <div>
                <div className={classes.impressum}>
                    <p>
                        <strong>&copy; {new Date().getFullYear()} by SNM GEBIOMIZED GMBH</strong>
                    </p>
                    <p>
                        Wilhelm-Schickard-Str. 12
                        <br />
                        48149 Muenster, Germany
                        <br />
                        {t('info.tel')}.: +49 251 / 9 87 24 35
                        <br />
                        {t('info.fax')}.: +49 251 / 9 87 24 22
                    </p>
                    <Text text={t('info.info')} />
                </div>
            </div>
            <div className={classes.technical}>
                <p>{t('info.createdBy')}</p>
                <p>
                    <a
                        href="https://www.geenen-it-systeme.de/"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <img src={geenenLogo} alt="Geenen IT-Systeme Logo" />
                    </a>
                </p>
                <a
                    href="http://www.geenen-it-systeme.de/"
                    target="_blank"
                    rel="noreferrer noopener"
                >
                    www.geenen-it-systeme.de
                </a>
            </div>
            <div>
                <IconButton label={t('info.buttonBackToHome')} to="/" icon="home" />
            </div>
        </Grid>
    );
};

export default InfoPage;
