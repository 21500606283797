import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { SoleSide } from '../../assets/soleFormConfig';
import image_kat1_left from '../../assets/soles/Kat1_L.png';
import image_kat1_right from '../../assets/soles/Kat1_R.png';
import image_kat2_left from '../../assets/soles/Kat2_L.png';
import image_kat2_right from '../../assets/soles/Kat2_R.png';
import image_kat3_left from '../../assets/soles/Kat3_L.png';
import image_kat3_right from '../../assets/soles/Kat3_R.png';
import { calculateSole, insoleSize, SoleResultInterface } from '../../helpers/sole-results';
import { StoreContext } from '../../providers/StoreProvider';
import { ITheme } from '../../theme';
import Icon from '../Icon';

interface Sole {
    use: (points: number) => boolean;
    name: string;
    [SoleSide.LEFT]: string;
    [SoleSide.RIGHT]: string;
}

const soles: Sole[] = [
    {
        use: points => points < 1.5,
        name: 'sole1',
        [SoleSide.LEFT]: image_kat1_left,
        [SoleSide.RIGHT]: image_kat1_right,
    },
    {
        use: points => points >= 1.5 && points < 2.5,
        name: 'sole2',
        [SoleSide.LEFT]: image_kat2_left,
        [SoleSide.RIGHT]: image_kat2_right,
    },
    {
        use: points => points >= 2.5,
        name: 'sole3',
        [SoleSide.LEFT]: image_kat3_left,
        [SoleSide.RIGHT]: image_kat3_right,
    },
];

const SoleResult = (): ReactElement => {
    const classes = useStyle();
    const [soleResult, setSoleResult] = useState<SoleResultInterface | null>(null);
    const { results, config } = useContext(StoreContext);
    const { t } = useTranslation();

    useEffect(() => {
        try {
            const result = calculateSole(results, config);
            setSoleResult(result);
        } catch (e) {
            console.group('Error while calculation result');
            console.error(e);
            console.log('Result', results);
            console.log('Config', config);
            console.groupEnd();
        }
    }, [results, config]);

    const resultSide = (side: SoleSide) => {
        if (soleResult === null) {
            return null;
        }

        const result = soleResult[side];
        const sole = soles.find(sole => sole.use(result.points));

        if (sole === undefined) {
            return <>Invalid soles configuration</>;
        }

        const size = results.get(`${side}_shoe_length`) as number;
        const length = insoleSize(size);

        return (
            <>
                <h2>{t(side === SoleSide.RIGHT ? 'sole.foot_right' : 'sole.foot_left')}</h2>
                <div className={classes.sideImageWrapper}>
                    <div className={classes.sideName}>
                        <strong>{t(`sole.soles.${sole.name}`)}</strong>
                        <br />
                        {t('sole.result.push_size')} {length}
                    </div>
                    <img
                        className={classes.sideImage}
                        src={sole[side]}
                        alt={`Sole ${t(`sole.soles.${sole.name}`)}`}
                    />
                </div>
            </>
        );
    };

    return (
        <>
            <div>
                <h1>{t('sole.result.title')}</h1>
            </div>
            <div>
                {soleResult === null ? (
                    <Icon icon="loading" spin={true} className={classes.spinner} />
                ) : (
                    <div className={classes.wrapper}>
                        <div>{resultSide(SoleSide.LEFT)}</div>
                        <div>{resultSide(SoleSide.RIGHT)}</div>
                    </div>
                )}
            </div>
        </>
    );
};

export default SoleResult;

const useStyle = createUseStyles((theme: ITheme) => ({
    wrapper: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: `${theme.spacing.outer}px`,
    },
    sideImageWrapper: {
        position: 'relative',
    },
    sideImage: {
        display: 'inline-block',
        width: 'auto',
        height: '30vh',
        marginTop: theme.spacing.inner,
        marginBottom: theme.spacing.inner,
    },
    sideName: {
        position: 'relative',
        bottom: 0,
        background: theme.palette.ci.primary,
        color: theme.palette.main.background,
        width: '100%',
        marginTop: 50,
        marginBottom: 50,
    },
    spinner: {
        fontSize: theme.typography.headline.size,
    },
}));
