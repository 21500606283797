import React, {ReactElement} from 'react';
import {createUseStyles} from 'react-jss';
import { useTranslation } from 'react-i18next';
import i18next from '../../i18n';
import {SoleSide} from '../../assets/soleFormConfig';
import {IConfigField} from '../../gebiomized';
import {ITheme} from '../../theme';
import Range from '../form/Range';
import SoleNumberInput from './SoleNumberInput';
import SoleRadioInput from './SoleRadioInput';

interface Props {
    field: IConfigField;
}

export const soleSides = {
    left: { label: i18next.t('sole.foot_left') },
    right: { label: i18next.t('sole.foot_right') },
};

const SoleSection = ({ field }: Props): ReactElement => {
    const classes = useStyle();
    const { t } = useTranslation();

    function renderRange() {
        return (
            <div className={classes.wrapper}>
                <div>
                    <Range field={field} fieldKeyPrefix="left" />
                </div>
                <div>
                    {field?.soleImage && (
                        <img src={field.soleImage} className={classes.img} alt="" />
                    )}
                </div>
                <div>
                    <Range field={field} fieldKeyPrefix="right" />
                </div>
            </div>
        );
    }

    function renderRadio() {
        return (
            <div>
                {field?.soleImage && <img src={field.soleImage} className={classes.img} alt="" />}
                {field.radio?.map(radio => (
                    <div className={classes.wrapper} key={radio.value}>
                        <div>
                            <SoleRadioInput
                                field={field}
                                value={radio.value}
                                side={SoleSide.LEFT}
                            />
                        </div>
                        <div>{t(radio.label)}</div>
                        <div>
                            <SoleRadioInput
                                field={field}
                                value={radio.value}
                                side={SoleSide.RIGHT}
                            />
                        </div>
                    </div>
                ))}
            </div>
        );
    }

    function renderNumberInput() {
        return (
            <div className={classes.wrapper}>
                <div>
                    <SoleNumberInput field={field} side={SoleSide.LEFT} />
                </div>
                <div>
                    {field?.soleImage && (
                        <img src={field.soleImage} className={classes.img} alt="" />
                    )}
                </div>
                <div>
                    <SoleNumberInput field={field} side={SoleSide.RIGHT} />
                </div>
            </div>
        );
    }

    return (
        <div>
            <div className={classes.wrapper}>
                <div>
                    <h2>{t('sole.foot_left')}</h2>
                </div>
                <div>
                    <h2 className={classes.label}>{t(field.title)}</h2>
                </div>
                <div>
                    <h2>{t('sole.foot_right')}</h2>
                </div>
            </div>
            {field.range ? renderRange() : null}
            {field.radio ? renderRadio() : null}
            {field.numberInput ? renderNumberInput() : null}
        </div>
    );
};

export default SoleSection;

const useStyle = createUseStyles((theme: ITheme) => ({
    wrapper: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        marginBottom: theme.spacing.outer,
        gap: theme.spacing.outer,
    },
    label: {
        color: theme.palette.main.text,
    },
    img: {
        width: '100%',
        height: 'auto',
        maxWidth: 400,
    },
}));
