import React, { useContext, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import gebiomizedLogo from '../assets/images/ssc-logo.svg';
import Grid from '../components/Grid';
import IconButton from '../components/IconButton';
import { IStoreContext, Selector, StoreContext } from '../providers/StoreProvider';
import { ITheme, saddlePalette, solePalette } from '../theme';

const StartPage = () => {
    const classes = useStyle();
    const {
        reset,
        isLoggedInSaddle,
        isLoggedInSole,
        loginSaddle,
        loginSole,
        language,
        enterSole,
        enterSaddle,
    }: IStoreContext = useContext(StoreContext);
    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('appName');
    }, [t]);

    const block = (selector: Selector) => {
        const onClickStartButton = () => {
            reset();
            if (selector === Selector.Sole) {
                enterSole();
            } else {
                enterSaddle();
            }
        };

        const onSubmitLogin = (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const login = selector === Selector.Sole ? loginSole : loginSaddle;

            const formData = new FormData(event.currentTarget);
            const password: string = formData.get('password')?.toString() ?? '';

            if (!login(password)) {
                alert(t('start.wrongPasswordMessage'));
                return;
            }
            onClickStartButton();
        };

        const isLoggedIn = selector === Selector.Sole ? isLoggedInSole : isLoggedInSaddle;
        if (!isLoggedIn) {
            return (
                <form onSubmit={onSubmitLogin} className={classes.form}>
                    <input
                        type="password"
                        name="password"
                        required={true}
                        placeholder={t('start.inputPassword')}
                    />
                    <IconButton
                        type="submit"
                        label={t('start.buttonSubmitPassword')}
                        icon="start"
                    />
                </form>
            );
        }

        return (
            <div>
                <IconButton
                    onClick={onClickStartButton}
                    label={t('start.buttonStart')}
                    icon="start"
                />
            </div>
        );
    };

    return (
        <Grid>
            <div>
                <img
                    src={gebiomizedLogo}
                    alt={`${t('companyName')} Logo`}
                    className={classes.img}
                />
            </div>
            <div>
                <h1 className={classes.title}>{t('appName')}</h1>
                <span className={classes.gebiomized}>
                    by{' '}
                    <a
                        href={t('companyUrl')}
                        rel="noreferrer noopener"
                        target="_blank"
                        title={t('start.secretSaddleLinkTile', {
                            link: t('companyUrl'),
                        })}
                    >
                        {t('companyName')}
                    </a>
                </span>
            </div>
            <div className={classes.blockWrapper}>
                <div className={[classes.block, classes.blockSaddle].join(' ')}>
                    <h2>{t('start.saddleLabel')}</h2>
                    {block(Selector.Saddle)}
                </div>
                <div className={[classes.block, classes.blockSole].join(' ')}>
                    <h2>{t('start.soleLabel')}</h2>
                    {block(Selector.Sole)}
                </div>
            </div>
            <div className={classes.info}>
                {language && (
                    <>
                        <IconButton
                            to="/settings"
                            label={t('start.buttonSettings')}
                            icon="settings"
                            className={classes.infoButton}
                        />
                        <IconButton
                            to="/info"
                            label={t('start.buttonInfo')}
                            icon="info"
                            className={classes.infoButton}
                        />
                    </>
                )}
            </div>
        </Grid>
    );
};

export default StartPage;

const useStyle = createUseStyles((theme: ITheme) => ({
    gebiomized: {
        display: 'block',
        fontSize: theme.typography.normal.size,
        color: theme.palette.main.sub,

        '& a': {
            color: theme.palette.ci.primary,
            textDecoration: 'none',
        },
    },
    img: {
        height: '20vh',
        maxHeight: 150,
        width: 'auto',
    },
    title: {
        color: theme.palette.main.text,
    },
    blockWrapper: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: theme.spacing.outer,
    },
    block: {},
    blockSole: {
        '& button, & button svg:hover, & h2': {
            color: solePalette.ci.primary,
        },
        '& input:focus': {
            borderColor: solePalette.ci.primary,
        },
    },
    blockSaddle: {
        '& button, & button svg:hover, & h2': {
            color: saddlePalette.ci.primary,
        },
        '& input:focus': {
            borderColor: saddlePalette.ci.primary,
        },
    },
    form: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        alignItems: 'center',
        maxWidth: 400,
        margin: '0 auto',

        '& input': {
            width: '100%',
            fontSize: theme.typography.normal.size,
            padding: `5px 10px`,
            background: 'transparent',
            borderRadius: theme.border.radius,
            border: theme.border.iconButton,
            color: theme.palette.main.text,
            transition: theme.transition('all'),
            fontFamily: theme.typography.normal.family,

            '&:focus': {
                outline: 'none',
            },
        },
    },
    info: {
        display: 'grid',
        justifyItems: 'center',
        gridTemplateColumns: 'auto auto',
    },
    infoButton: {
        '& a': {
            color: theme.palette.main.sub,
        },
    },
}));
