import React, { ReactElement, useContext } from 'react';
import Grid from '../components/Grid';
import ResetButton from '../components/ResetButton';
import SaddleResult from '../components/saddle/SaddleResult';
import SoleResult from '../components/sole/SoleResult';
import { IStoreContext, Selector, StoreContext } from '../providers/StoreProvider';

const ResultPage = (): ReactElement => {
    const { selector }: IStoreContext = useContext(StoreContext);

    if (selector !== Selector.Sole && selector !== Selector.Saddle) {
        throw new Error(`Unknown selector type ${selector}`);
    }

    return (
        <Grid>
            {selector === Selector.Sole ? <SoleResult /> : <SaddleResult />}
            <div>
                <ResetButton />
            </div>
        </Grid>
    );
};

export default ResultPage;
