import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import GlobalStyles from './components/GlobalStyles';
import Layout from './components/Layout';
import ErrorPage from './pages/ErrorPage';
import FormPage from './pages/FormPage';
import InfoPage from './pages/InfoPage';
import ResultPage from './pages/ResultPage';
import SettingsPage from './pages/SettingsPage';
import StartPage from './pages/StartPage';
import StoreProvider from './providers/StoreProvider';

const CustomRouter = ({ children }: any) => (
    <>
        <Layout>
            <GlobalStyles />
            <Routes>{children}</Routes>
        </Layout>
        <div id='portal' />
    </>
);

const listener = () => {
    // We execute the same script as before
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
};

const App = () => {
    useEffect(() => {
        listener();
        window.addEventListener('resize', listener);

        return window.removeEventListener('resize', listener);
    }, []);

    return (
        <StoreProvider>
            <CustomRouter>
                <Route path="/" element={<StartPage />} />
                <Route path="form/:page" element={<FormPage />} />
                <Route path="/result" element={<ResultPage />} />
                <Route path="/settings" element={<SettingsPage />} />
                <Route path="/info" element={<InfoPage />} />
                <Route path="*" element={<ErrorPage />} />
            </CustomRouter>
        </StoreProvider>
    );
};
export default App;
