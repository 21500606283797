import React, { memo } from 'react';

interface IProps {
    text: string;
}

const Text = ({ text }: IProps) => {
    return (
        <p>
            {text.split('\n').map((item, key) => {
                return (
                    <React.Fragment key={item}>
                        {item}
                        <br />
                    </React.Fragment>
                );
            })}
        </p>
    );
};

export default memo(Text);
