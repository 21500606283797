import React from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { IConfigFieldOption } from '../../gebiomized';
import { ITheme } from '../../theme';
import IconButton from '../IconButton';

const useStyle = createUseStyles((theme: ITheme) => ({
    active: {
        '& svg': {
            color: theme.palette.ci.primary,
            fill: theme.palette.ci.primary,
        },
    },
    hidden: {
        opacity: 0.2,
    },
}));

interface IProps {
    option: IConfigFieldOption;
    onClickOption: { (value: string): void };
    value: string;
}

const SectionOption = ({ option, onClickOption, value }: IProps) => {
    const classes = useStyle();
    const { t } = useTranslation();

    const onClick = () => {
        onClickOption(option.value);
    };

    return (
        <IconButton
            className={[
                value.length > 0 && value !== option.value ? classes.hidden : '',
                value === option.value ? classes.active : '',
            ].join(' ')}
            key={option.label}
            onClick={onClick}
            icon={option.icon}
            label={t(option.label)}
            isIconVertical={option.isIconVertical || false}
        />
    );
};

export default SectionOption;
