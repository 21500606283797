import React from 'react';
import { createUseStyles } from 'react-jss';
import { ITheme } from '../../theme';

const useStyle = createUseStyles((theme: ITheme) => ({
    progress: {
        height: 20,
        position: 'relative',
        overflow: 'hidden',
        background: theme.palette.third.sub,
        borderTopLeftRadius: theme.border.radius,
        borderTopRightRadius: theme.border.radius,

        [theme.mediaQueries.mobile.max]: {
            height: 10,
        },
        [theme.mediaQueries.tablet.max]: {
            borderRadius: 0,
        },

        '& > div': {
            position: 'absolute',
            top: 0,
            left: 0,
            background: theme.palette.ci.primary,
            bottom: 0,
            borderRadius: 0,
        },
    },
}));

interface IProps {
    currentPageNumber: number;
    totalPagesCount: number;
}

const ProgressBar = ({ currentPageNumber, totalPagesCount }: IProps) => {
    const classes = useStyle();
    return (
        <div className={classes.progress}>
            <div
                style={{
                    width: `${(100 / totalPagesCount) * currentPageNumber}%`,
                }}
            />
        </div>
    );
};

export default ProgressBar;
