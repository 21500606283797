import React from 'react';
import { createUseStyles } from 'react-jss';
import { motion, Variants } from 'framer-motion';
import { ITheme } from '../theme';

const useStyle = createUseStyles((theme: ITheme) => ({
    grid: {
        display: 'grid',
        alignContent: 'space-between',
        gridGap: `${theme.spacing.outer}px`,
    },
}));

interface IProps {
    children: any;
    className?: string;
}

const Grid = ({ children, className }: IProps) => {
    const classes = useStyle();
    const variants: Variants = {
        enter: {
            transition: {
                when: 'beforeChildren',
                staggerChildren: 0.1,
            }
        },
        exit: {
            transition: {
                staggerChildren: 0.1,
                staggerDirection: -1,
            },
        },
    }

    return (
        <motion.div
            className={[classes.grid, className].join(' ')}
            variants={variants}
            animate="enter"
            exit="exit"
        >
            {children}
        </motion.div>
    );
};

export default Grid;
