import image_sole_length from '../assets/soles-quiz/foot_length.jpg';
import image_foot_zones from '../assets/soles-quiz/foot_zones.png';
import image_leg_squad from '../assets/soles-quiz/leg_squad.jpg';
import image_navicular_unloaded from '../assets/soles-quiz/naviculometer_1a.png';
import image_navicular_loaded from '../assets/soles-quiz/naviculometer_1b.png';
import { Config, IConfigFieldRangeLabel } from '../gebiomized';

const navicularLabels = [
    {
        value: 15,
        label: '<= 15',
    },
    ...Array.from(Array(11).keys()).map(
        (index: number) =>
            ({
                value: 16 + index,
                label: (16 + index).toString(),
            } as IConfigFieldRangeLabel)
    ),
    {
        value: 26,
        label: '25 <',
    },
];

export enum SoleSide {
    LEFT = 'left',
    RIGHT = 'right',
}

const config: Config = {
    pages: [
        {
            fields: [
                {
                    title: 'sole.quiz.foot_length',
                    key: 'foot_length',
                    numberInput: {
                        min: 240,
                        max: 310,
                        step: 1,
                        default: 270,
                    },
                    soleImage: image_sole_length,
                },
                {
                    title: 'sole.quiz.shoe_length',
                    key: 'shoe_length',
                    numberInput: {
                        min: 39,
                        max: 48,
                        step: 0.5,
                        default: 42,
                    },
                },
            ],
        },
        {
            title: 'sole.quiz.navicular_title',
            fields: [
                {
                    title: 'sole.quiz.navicular_unloaded',
                    key: 'navicular_unloaded',
                    range: {
                        min: navicularLabels[0].value,
                        max: navicularLabels[navicularLabels.length - 1].value,
                        showValue: false,
                        labels: navicularLabels,
                    },
                    soleImage: image_navicular_unloaded,
                },
                {
                    title: 'sole.quiz.navicular_loaded',
                    key: 'navicular_loaded',
                    range: {
                        min: navicularLabels[0].value,
                        max: navicularLabels[navicularLabels.length - 1].value,
                        showValue: false,
                        labels: navicularLabels,
                    },
                    soleImage: image_navicular_loaded,
                },
            ],
        },
        {
            fields: [
                {
                    title: 'sole.quiz.leg_squad_title',
                    key: 'single_leg_squad',
                    soleImage: image_leg_squad,
                    radio: [
                        {
                            label: 'sole.quiz.leg_squad_stable',
                            value: 'stable',
                        },
                        {
                            label: 'sole.quiz.leg_squad_slightly',
                            value: 'slightly',
                        },
                        {
                            label: 'sole.quiz.leg_squad_knee',
                            value: 'knee',
                        },
                    ],
                },
            ],
        },
        {
            fields: [
                {
                    title: 'sole.quiz.numb_feet',
                    key: 'numb_feet',
                    range: {
                        min: 0,
                        max: 5,
                        showValue: false,
                        labels: [
                            {
                                value: 0,
                                label: 'sole.quiz.numb_feet_no',
                            },
                            {
                                value: 1,
                                label: '',
                            },
                            {
                                value: 2,
                                label: '',
                            },
                            {
                                value: 3,
                                label: '',
                            },
                            {
                                value: 4,
                                label: '',
                            },
                            {
                                value: 5,
                                label: 'sole.quiz.numb_feet_extreme',
                            },
                        ],
                    },
                },
            ],
        },
        {
            fields: [
                {
                    title: 'sole.quiz.pressure_pain_title',
                    key: 'pressure_pain',
                    soleImage: image_foot_zones,
                    radio: [
                        {
                            label: 'sole.quiz.pressure_pain_area1',
                            value: 'area1',
                        },
                        {
                            label: 'sole.quiz.pressure_pain_area2',
                            value: 'area2',
                        },
                        {
                            label: 'sole.quiz.pressure_pain_no',
                            value: 'no',
                        },
                    ],
                },
            ],
        },
    ],
};

export default config;
