import React, { ChangeEvent, ReactElement, useContext, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { SoleSide } from '../../assets/soleFormConfig';
import { IConfigField } from '../../gebiomized';
import { StoreContext } from '../../providers/StoreProvider';
import { ITheme } from '../../theme';

interface Props {
    field: IConfigField;
    side: SoleSide;
}

const SoleNumberInput = ({ field, side }: Props): ReactElement | null => {
    const classes = useStyle();
    const { results, setOption } = useContext(StoreContext);
    const fieldKey = `${side}_${field.key}`;

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const valueAsNumber =
            value.includes(',') || value.includes('.') ? parseFloat(value) : parseInt(value);
        setOption(fieldKey, valueAsNumber);
    };

    useEffect(() => {
        if (!field.numberInput) {
            return;
        }

        setOption(fieldKey, field.numberInput.default ?? field.numberInput.min);
    }, [fieldKey, field.numberInput, setOption]);

    if (!field.numberInput) {
        return null;
    }

    return (
        <input
            className={classes.input}
            onChange={onChange}
            type="number"
            value={results.get(fieldKey) ?? '0'}
            min={field.numberInput.min}
            max={field.numberInput.max}
            step={field.numberInput.step}
        />
    );
};

export default SoleNumberInput;

const useStyle = createUseStyles((theme: ITheme) => ({
    input: {
        padding: theme.spacing.inner,
        fontSize: `${theme.typography.normal.size}px`,
        background: 'transparent',
        border: `2px solid ${theme.palette.ci.primary}`,
        color: theme.palette.main.text,
        borderRadius: 5,
        outline: 'none',
    },
}));
