import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { AnimatePresence, motion, Variants } from 'framer-motion';
import imageSSCResult from '../../assets/images/ssc-result.svg';
import { IResultSaddle } from '../../gebiomized';
import { calculateSaddle, createTestResult } from '../../helpers/saddle-results';
import { IStoreContext, StoreContext } from '../../providers/StoreProvider';
import { ITheme } from '../../theme';
import Icon from '../Icon';

const isInDebugMode = process.env.REACT_APP_DEBUG === 'true';
const useDebugData = process.env.REACT_APP_RANDOM === 'true';

const SaddleResult = (): ReactElement => {
    const classes = useStyle();

    const { results, config }: IStoreContext = useContext(StoreContext);

    const [poseEnter, setPoseEnter] = useState(false);
    const [calculatedResults, setCalculatedResults] = useState([] as IResultSaddle[]);

    useEffect(() => {
        const tr = createTestResult(config);
        const saddle = calculateSaddle(useDebugData ? tr : results, config);
        setCalculatedResults(saddle);

        setTimeout(() => {
            setPoseEnter(true);
        }, 1);
    }, [results, config]);

    const reveal: Variants = {
        enter: {
            transition: {
                when: 'beforeChildren',
                staggerChildren: 0.1,
            }
        },
    };

    const revealChildren: Variants = {
        enter: {
            y: 0,
            opacity: 1,
        },
        exit: {
            y: -20,
            opacity: 0,
        },
    };

    const finalResult: IResultSaddle = calculatedResults[0];

    return (
        <>
            <div>
                <div
                    style={
                        isInDebugMode
                            ? { height: '100%', overflow: 'hidden', paddingBottom: 50 }
                            : {}
                    }
                >
                    {isInDebugMode ? (
                        <ol className={classes.debug}>
                            {calculatedResults.map((rs: IResultSaddle) => (
                                <li key={rs.name}>
                                    <p>
                                        <strong>{rs.name}</strong> ({rs.points} Punkte)
                                    </p>
                                    <ul>
                                        {rs.features.map(s => (
                                            <li key={s}>{s}</li>
                                        ))}
                                    </ul>
                                </li>
                            ))}
                        </ol>
                    ) : (
                        <>
                            <AnimatePresence exitBeforeEnter>
                                {poseEnter && (
                                    <motion.div key="reveal" variants={reveal} initial="exit" animate="enter" exit="exit">
                                        <motion.div variants={revealChildren}>
                                            <div className={classes.saddleImageWrapper}>
                                                <img
                                                    src={imageSSCResult}
                                                    alt=""
                                                    className={classes.saddleImageBackground}
                                                />
                                                <img
                                                    src={finalResult.image}
                                                    alt={finalResult.name}
                                                    className={classes.saddleImage}
                                                />
                                                <div className={classes.saddleImageTitle}>
                                                    {finalResult.name}
                                                </div>
                                            </div>
                                        </motion.div>
                                        <motion.div variants={revealChildren}>
                                            <ul className={classes.args}>
                                                {finalResult.features.map(arg => (
                                                    <li key={arg}>
                                                        <Icon icon="confirm" /> {arg}
                                                    </li>
                                                ))}
                                            </ul>
                                        </motion.div>
                                    </motion.div>
                                )}
                            </AnimatePresence>
                            {!poseEnter && (
                                <div>
                                    <Icon icon="loading" spin={true} className={classes.spinner} />
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default SaddleResult;

const useStyle = createUseStyles((theme: ITheme) => ({
    name: {
        fontSize: theme.typography.headline.size,
        marginTop: theme.spacing.outer,
        color: theme.palette.ci.primary,
        lineHeight: 1,
    },
    args: {
        width: 'auto',
        display: 'inline-block',
        margin: '0 auto',
        marginTop: theme.spacing.outer,
        padding: 0,
        '& > li': {
            listStyle: 'none',
            lineHeight: 2,
            textAlign: 'left',
            fontSize: theme.typography.normal.size,
            '& > *': {
                color: theme.palette.ci.primary,
                marginRight: theme.spacing.inner,
            },
        },
    },
    spinner: {
        fontSize: theme.typography.headline.size,
    },
    saddleImageWrapper: {
        position: 'relative',
        maxWidth: 450,
        width: '100%',
        display: 'inline-block',
    },
    saddleImageBackground: {
        width: '100%',
        height: 'auto',
    },
    saddleImage: {
        width: '60%',
        height: 'auto',
        position: 'absolute',
        top: '43%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    saddleImageTitle: {
        position: 'absolute',
        top: '72%',
        left: '50%',
        transform: 'translate(-50%, -50%) rotate(-8.5deg)',
        fontSize: theme.typography.headline.size,
        whiteSpace: 'nowrap',
    },
    debug: {
        maxHeight: '50vh',
        overflow: 'scroll',
        height: '100%',
        '& li': {
            textAlign: 'left',
        },
    },
}));
