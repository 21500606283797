import de from './translations/de.json';
import en from './translations/en.json';
import nl from './translations/nl.json';
import pl from './translations/pl.json';

export interface ILang {
    key: Language;
    name: string;
    config: any;
}

export enum Language {
    En = 'en',
    De = 'de',
    Nl = 'nl',
    Pl = 'pl',
}

const lang: ILang[] = [
    {
        key: Language.De,
        name: 'Deutsch',
        config: de,
    },
    {
        key: Language.En,
        name: 'English',
        config: en,
    },
    {
        key: Language.Nl,
        name: 'Nederlands',
        config: nl,
    },
    {
        key: Language.Pl,
        name: 'Polski',
        config: pl,
    },
];
export default lang;
