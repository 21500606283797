import React from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import { ITheme } from '../theme';
import Icon, { IIcon } from './Icon';

const useStyle = createUseStyles((theme: ITheme) => ({
    wrapper: {
        width: '15vw',
        maxWidth: 100,
        display: 'inline-block',
        transition: theme.transition('all'),

        '& > *': {
            display: 'inline-block',
            position: 'relative',
            paddingBottom: '100%',
            background: `transparent`,
            width: '100%',
            borderRadius: theme.border.radius,
            border: 'none',
            cursor: 'pointer',
            verticalAlign: 'bottom',
            transition: theme.transition('all'),
            color: theme.palette.main.text,

            '&:focus': {
                outline: 0,
            },

            '&:hover': {
                '& > *': {
                    color: `${theme.palette.ci.primary}`,
                },
            },

            '&:disabled': {
                '& > *': {
                    color: `${theme.palette.main.border} !important`,
                },
            },

            '& > *': {
                width: '50%',
                height: 'auto',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                fontSize: `calc(${theme.typography.headline.size} * 2)`,
                transition: theme.transition('all'),

                '&.svg': {
                    width: '100%',
                },
            },
        },
    },
    vertical: {
        width: '60% !important',
    },
}));

interface IProps {
    icon: IIcon;
    label: string;
    onClick?: { (): void };
    to?: string;
    className?: string;
    disabled?: boolean;
    isIconVertical?: boolean;
    type?: 'button' | 'submit';
}

const IconButton = ({
    to,
    icon,
    label,
    onClick,
    className,
    disabled,
    isIconVertical,
    type = 'button',
}: IProps) => {
    const classes = useStyle();

    const Img = (
        <Icon className={['fa-icon', isIconVertical && classes.vertical].join(' ')} icon={icon} />
    );

    return (
        <div className={[classes.wrapper, className].join(' ')}>
            {to ? (
                <Link to={to} title={label}>
                    {Img}
                </Link>
            ) : (
                <button title={label} onClick={onClick} type={type} disabled={disabled || false}>
                    {Img}
                </button>
            )}
        </div>
    );
};

export default IconButton;
