import imageBeweglichkeitstest from '../assets/images/2-Kreuzbeinwinkeltest.jpg';
import { Config, IConfigField, IConfigFieldOption, IConfigFieldRangeLabel } from '../gebiomized';

const config: Config = {
    pages: [
        {
            fields: [
                {
                    title: 'forms.gender.title',
                    key: 'gender',
                    options: [
                        {
                            label: 'forms.gender.male',
                            value: 'male',
                            icon: 'form_gender_male',
                        } as IConfigFieldOption,
                        {
                            label: 'forms.gender.female',
                            value: 'female',
                            icon: 'form_gender_female',
                        } as IConfigFieldOption,
                    ],
                    // tip: 'You cannot pick two',
                } as IConfigField,
                // Vorerst deaktiviert
                // {
                //     title: 'Disziplin',
                //     key: 'discipline',
                //     options: [
                //         {
                //             label: 'RR',
                //             value: 'rr',
                //             icon: 'form_discipline_rr',
                //         } as IConfigFieldOption,
                //         {
                //             label: 'MBT',
                //             value: 'mbt',
                //             icon: 'form_discipline_mbt',
                //         } as IConfigFieldOption,
                //         {
                //             label: 'Gravel',
                //             value: 'gravel',
                //             icon: 'form_discipline_gravel',
                //         } as IConfigFieldOption,
                //     ],
                // } as IConfigField,
                {
                    title: 'forms.position.title',
                    key: 'position',
                    options: [
                        {
                            label: 'forms.position.aufrecht',
                            value: 'aufrecht',
                            icon: 'form_position_aufrecht',
                        } as IConfigFieldOption,
                        {
                            label: 'forms.position.moderat',
                            value: 'moderat',
                            icon: 'form_position_moderat',
                        } as IConfigFieldOption,
                        {
                            label: 'forms.position.aerodynamisch',
                            value: 'aerodynamisch',
                            icon: 'form_position_aerodynamisch',
                        } as IConfigFieldOption,
                    ],
                } as IConfigField,
            ],
        },
        {
            fields: [
                {
                    title: 'forms.contactArea.title',
                    key: 'contactArea',
                    options: [
                        {
                            label: 'forms.contactArea.front',
                            value: 'front',
                            icon: 'form_contactarea_front',
                            isIconVertical: true,
                        } as IConfigFieldOption,
                        {
                            label: 'forms.contactArea.middle',
                            value: 'middle',
                            icon: 'form_contactarea_middle',
                            isIconVertical: true,
                        } as IConfigFieldOption,
                        {
                            label: 'forms.contactArea.back',
                            value: 'back',
                            icon: 'form_contactarea_back',
                            isIconVertical: true,
                        } as IConfigFieldOption,
                    ],
                } as IConfigField,
                {
                    title: 'forms.friction.title',
                    key: 'friction',
                    options: [
                        {
                            label: 'forms.friction.no',
                            value: 'no',
                            icon: 'close',
                        } as IConfigFieldOption,
                        {
                            label: 'forms.friction.yes',
                            value: 'yes',
                            icon: 'confirm',
                        } as IConfigFieldOption,
                    ],
                } as IConfigField,
                {
                    title: 'forms.sensitivity.title',
                    key: 'sensitivity',
                    range: {
                        min: 1,
                        max: 10,
                        showValue: true,
                        labels: [
                            {
                                value: 1,
                                label: 'forms.sensitivity.min',
                            } as IConfigFieldRangeLabel,
                            {
                                value: 10,
                                label: 'forms.sensitivity.max',
                            } as IConfigFieldRangeLabel,
                        ],
                    },
                } as IConfigField,
            ],
        },
        {
            fields: [
                {
                    title: 'forms.movement.title',
                    key: 'movement',
                    range: {
                        min: 1,
                        max: 4,
                        showValue: false,
                        labels: [
                            {
                                value: 1,
                                label: '< 10°',
                            } as IConfigFieldRangeLabel,
                            {
                                value: 2,
                                label: '10° - 20°',
                            } as IConfigFieldRangeLabel,
                            {
                                value: 3,
                                label: '20° - 30°',
                            } as IConfigFieldRangeLabel,
                            {
                                value: 4,
                                label: '> 40°',
                            } as IConfigFieldRangeLabel,
                        ],
                    },
                    unit: '°',
                    meta: {
                        description: 'forms.movement.description',
                        image: imageBeweglichkeitstest,
                    },
                } as IConfigField,
            ],
        },
    ],
};

export default config;
