import { SoleSide } from '../assets/soleFormConfig';
import { Config, IResult } from '../gebiomized';

export const getConfigFieldKeys = (config: Config): string[] => {
    return config.pages.reduce((carry: string[], page) => {
        page.fields.forEach(field => {
            carry.push(field.key);
        });
        return carry;
    }, []);
};

type Value = (value: number) => number;

const numbFeedPointsPerStack = 0.75 / 5;
const calculations: { [key: string]: { [key: string]: Value } } = {
    single_leg_squad: {
        stable: (value: number): number => value,
        slightly: (value: number): number => value - 0.25,
        knee: (value: number): number => value + 0.5,
    },
    numb_feet: {
        0: (value: number): number => value,
        1: (value: number): number => value + numbFeedPointsPerStack,
        2: (value: number): number => value + numbFeedPointsPerStack * 2,
        3: (value: number): number => value + numbFeedPointsPerStack * 3,
        4: (value: number): number => value + numbFeedPointsPerStack * 4,
        5: (value: number): number => value + numbFeedPointsPerStack * 5,
    },
    pressure_pain: {
        area1: (value: number): number => value - 0.5,
        area2: (value: number): number => value + 0.5,
        no: (value: number): number => value,
    },
};

export const insoleSize = (footSize: number): string => {
    console.log(footSize);
    switch (footSize) {
        case 39:
        case 39.5:
        case 40:
            return '39/40';
        case 40.5:
        case 41:
        case 41.5:
        case 42:
            return '41/42';
        case 42.5:
        case 43:
        case 43.5:
        case 44:
            return '43/44';
        case 44.5:
        case 45:
        case 45.5:
        case 46:
            return '45/46';
        case 46.5:
        case 47:
        case 47.5:
        case 48:
            return '47/48';
        default:
            return '0';
    }
};

export const calculateField = (
    results: IResult,
    side: 'left' | 'right',
    key: string,
    carry: number
) => {
    const value = results.get(`${side}_${key}`);

    if (!value) {
        throw new Error(`No value for ${key}`);
    }

    if (key === 'foot_length' || key === 'numb_feet' || key === 'shoe_length') {
        return carry;
    }

    if (key === 'navicular_unloaded') {
        const numberValue = parseInt(value.toString());
        if (numberValue <= 15) return 1;
        if (numberValue > 15 && numberValue <= 18) return 1.5;
        if (numberValue >= 19 && numberValue <= 22) return 2;
        if (numberValue >= 23 && numberValue <= 25) return 2.5;
        if (numberValue >= 25) return 3;
    }

    if (key === 'navicular_loaded') {
        const unloadedValue = parseInt(
            results.get(`${side}_navicular_unloaded`)?.toString() ?? '0'
        );
        const difference = unloadedValue - parseInt(value.toString());
        if (difference > 5) {
            return carry - 0.75;
        }
        if (difference >= 3) {
            return carry - 0.5;
        }
        return carry;
    }

    if (!calculations[key] || !calculations[key][value]) {
        throw new Error(`No calculation for ${key} with value ${value}`);
    }

    return calculations[key][value](carry);
};

export const calculateSideResult = (
    side: 'left' | 'right',
    results: IResult,
    config: Config
): number => {
    const fieldKeys = getConfigFieldKeys(config);
    return fieldKeys.reduce(
        (carry: number, key: string) => calculateField(results, side, key, carry),
        0
    );
};

export interface SoleSideResult {
    points: number;
    length: number;
    shoe: number;
}

export interface SoleResultInterface {
    [SoleSide.LEFT]: SoleSideResult;
    [SoleSide.RIGHT]: SoleSideResult;
}

export const calculateSole = (results: IResult, config: Config): SoleResultInterface => {
    const left = calculateSideResult('left', results, config);
    const right = calculateSideResult('right', results, config);
    const lengthLeft = parseInt(results.get(`left_foot_length`)?.toString() ?? '0');
    const lengthRight = parseInt(results.get(`right_foot_length`)?.toString() ?? '0');
    const shoeLeft = parseInt(results.get('left_shoe_length')?.toString() ?? '0');
    const shoeRight = parseInt(results.get('right_shoe_length')?.toString() ?? '0');

    return {
        left: {
            points: left,
            length: lengthLeft,
            shoe: shoeLeft,
        },
        right: {
            points: right,
            length: lengthRight,
            shoe: shoeRight,
        },
    };
};
