import Slider from 'rc-slider/lib/Slider';
import React, { ReactElement, useContext, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { IConfigField } from '../../gebiomized';
import { IStoreContext, StoreContext } from '../../providers/StoreProvider';
import { ITheme } from '../../theme';

interface Props {
    field: IConfigField;
    fieldKeyPrefix?: string | null;
}

const Range = ({ field, fieldKeyPrefix = null }: Props): ReactElement | null => {
    const classes = useStyle();
    const { results, setOption } = useContext<IStoreContext>(StoreContext);
    const { t } = useTranslation();
    const fieldKey = fieldKeyPrefix ? `${fieldKeyPrefix}_${field.key}` : field.key;

    const onChangeOption = (v: (number | number[])) => {
        setOption(fieldKey, v.toString());
    };

    const value = results.get(fieldKey);
    const rangeValue: string | number = results.has(fieldKey) && value ? value : '';

    useEffect(() => {
        setOption(fieldKey, field.range?.min.toString() ?? '');
    }, [field.range?.min, fieldKey, setOption]);

    if (!field.range) {
        return null;
    }

    return (
        <div className={classes.range}>
            <Slider
                min={field.range.min}
                max={field.range.max}
                marks={field.range.labels.reduce(
                    (carry, label) => ({
                        ...carry,
                        [label.value]: field.range?.showValue ? '' : t(label.label),
                    }),
                    {}
                )}
                value={parseInt(rangeValue.toString(), 10) ?? field.range.min}
                onChange={onChangeOption}
                dots={false}
                included={false}
                className={field.range.labels.length > 6 ? 'big' : 'small'}
            />
            <span className="range-label" style={{ opacity: field.range.showValue ? 1 : 0 }}>
                {rangeValue ? rangeValue.toString() + (field.unit ? ` ${field.unit}` : '') : '-'}
            </span>
        </div>
    );
};

export default Range;

const useStyle = createUseStyles((theme: ITheme) => {
    return {
        range: {
            '& .rc-slider-handle': {
                border: 'none',
                backgroundColor: theme.palette.ci.primary,
                width: 25,
                height: 25,
                marginTop: -11,
            },
            '& .rc-slider-dot': {
                borderColor: theme.palette.main.border,
                backgroundColor: theme.palette.main.border,
            },
            '& .rc-slider-rail': {
                backgroundColor: theme.palette.main.border,
            },
            '& .rc-slider-track': {
                backgroundColor: theme.palette.ci.primary,
            },
            '& .rc-slider-mark-text': {
                fontSize: theme.typography.normal.size,
                whiteSpace: 'nowrap',
                color: theme.palette.main.sub,
            },
            '& .rc-slider-mark-text-active': {
                color: theme.palette.ci.primary,
            },
            '& .big': {
                '& .rc-slider-mark-text': {
                    opacity: 0,
                    transition: 'opacity 0.1s ease-in-out',
                },
                '& .rc-slider-mark-text.rc-slider-mark-text-active': {
                    opacity: 1,
                },
                '& .rc-slider-mark-text:first-child': {
                    opacity: 1,
                },
                '& .rc-slider-mark-text:last-child': {
                    opacity: 1,
                },
            },
        },
    };
});
