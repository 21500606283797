import React, { ReactElement, useContext } from 'react';
import { SoleSide } from '../../assets/soleFormConfig';
import { IConfigField } from '../../gebiomized';
import { StoreContext } from '../../providers/StoreProvider';
import SectionOption from '../form/SectionOption';

interface Props {
    field: IConfigField;
    value: string;
    side: SoleSide;
}

const SoleRadioInput = ({ field, value, side }: Props): ReactElement => {
    const { setOption, results } = useContext(StoreContext);

    const onChangeRadio = (key: string, value: string): void => {
        setOption(key, value);
    };

    return (
        <SectionOption
            option={{
                label: '',
                value: value,
                icon: 'confirm',
            }}
            value={results.get(`${side}_${field.key}`)?.toString() ?? ''}
            onClickOption={() => onChangeRadio(`${side}_${field.key}`, value)}
        />
    );
};

export default SoleRadioInput;
